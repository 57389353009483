//
//   Returns true if the element is in the viewport
//
//////////////////////////////////////////////////////////////////////
const isInViewport = (e, extraPadding) => {
  if (!e) {
    return null
  }

  var rect = e.getBoundingClientRect();
  var elemTop = rect.top;
  var elemBottom = rect.bottom;
  return elemTop < (window.innerHeight + extraPadding) && (elemBottom + extraPadding) >= 0;
};
export default isInViewport;
