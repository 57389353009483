import $ from 'jquery';
import isInViewport from '../modules/isInViewport.js';
import throttle from '../modules/throttle.js';

const CommerceRoute = () => {

  //
  //   Parallax Blocks
  //
  //////////////////////////////////////////////////////////////////////
  const $pluginBlocks = $('.js-plugin-block');
  const $pluginBlocksContainer = $('.js-plugin-blocks-container');
  const $pluginBlocksContainerTop = $pluginBlocksContainer.scrollTop();

  function parallaxBlocks(element, container) {

    function plax() {
      if (isInViewport(container[0], 200)) {
        $.each(element, function() {
          var scrollTop = $(window).scrollTop() - container.offset().top;
          var intensity = $(this).attr('data-intensity');
          var imgPos = scrollTop / intensity - scrollTop + 'px';
          $(this).css('transform', 'translateY(' + imgPos + ')');
        });
      }
    }

    // Trigger on Load
    $.each(element, function() {
      plax();
      $(window).trigger('resize');
    });
    
    // Throttle on Scroll
    window.addEventListener('scroll', throttle(plax, 30));
  }

  parallaxBlocks($pluginBlocks, $pluginBlocksContainer);
  
};

export default CommerceRoute;
