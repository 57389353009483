import $ from 'jquery';
import _throttle from 'lodash.throttle';

//
//   Ajax Search
//
//////////////////////////////////////////////////////////////////////
const ajaxSearch = (options) => {

  // Private Vars
  const self = {
    keyupMin: 3,
    activeClass: 'is-active',
    searchedClass: 'is-searched',
    isLoading: false,
    $loader: "Loading..."
  };

  // Pubic Vars
  self.settings = $.extend({
    $scope: $('body'),
    $form: '',
    $formContainer: '',
    $input: '',
    $resultsContainer: $('body'),
    $results: $('body'),
    loaderClasses: 'loading loading--centered'
  }, options);

  // Private Methods
  const _init = function() {
    if (self.settings.$form.length === 0 || self.settings.$input.length === 0) return;

    _createLoadingMarkup();
    _addEventListeners();
  };

  const _createLoadingMarkup = function() {
    const imgSrc = self.settings.$results.data('loading-img');

    if (imgSrc) {
      const imgTag = $('<img>').attr('src', imgSrc);
      self.$loader = $('<div></div>').addClass(self.settings.loaderClasses).append(imgTag);
    }
  };

  const _addEventListeners = function() {
    $('body').on('click', _handleScope);

    self.settings.$form.on('submit', _handleSubmit);
    self.settings.$input.on('input', _throttle(_handleInput, 2000));
  };

  const _setFocusState = function() {
    self.settings.$input.focus();
  };

  const _handleScope = function(evt) {
    if (!_isDescendant(evt.target)) {
      self.settings.$resultsContainer.removeClass(self.activeClass);
      self.settings.$input.val('');
    } 
  };

  const _handleInput = function(evt) {
    if (evt.currentTarget.value.length !== 0) {
      if (!self.isLoading) _showLoading();
      
      if (evt.currentTarget.value.length >= self.keyupMin) {
        self.settings.$form.submit();
      } 
    } else {
      self.settings.$resultsContainer.removeClass(self.activeClass);
    }
  };

  const _handleSubmit = function(evt) {
    evt.preventDefault();

    const $this = $(this);
    const data = $this.serialize();
    const url = `${$this.attr('action')}?${data}`;

    _makeRequest(url);
  };

  const _showLoading = function() {
    self.isLoading = true;

    self.settings.$resultsContainer.addClass(self.activeClass);

    if (self.settings.$formContainer) {
      self.settings.$formContainer.addClass(self.searchedClass);
    }

    self.settings.$results.html(self.$loader);
  };

  const _makeRequest = function(url) {
    $.ajax({
      method: 'GET',
      url,
      beforeSend: function() {
        if (!self.isLoading) _showLoading();
      },
      success (data, textStatus) {
        self.settings.$results.html(data);
      },
      complete (data, textStatus) {
        self.isLoading = false;
      },
      error (jqXHR, textStatus, error) {
        self.settings.$results.html('Oops, something went wrong. Please try again.');
      }
    });
  }

  const _isDescendant = function(child) {
    return $.contains(self.settings.$scope[0], child);
  };


  //   Public Methods
  //
  //////////////////////////////////////////////////////////////////////

  self.clearResults = function() {
    self.settings.$resultsContainer.removeClass(self.activeClass);

    if (self.settings.$formContainer) {
      self.settings.$formContainer.removeClass(self.searchedClass);
    }

    self.settings.$results.html('');
    self.isLoading = false;
  };


  // Initialize
  _init();

  // Return the Object
  return self;
}

export default ajaxSearch;
