import scrollTo from '../modules/scrollTo.js';

//
//   Scroll to Invalid Form Field
//
//////////////////////////////////////////////////////////////////////

const scrollToInvalid = (form, offset = 155, duration = 0, isModal = false, callback) => {

    form.addEventListener('invalid', function(e) {

        const first = $(form).find(':invalid')[0];

        if (e.target == first) {
            let scrollTarget = $(first).offset().top - offset;
            if (isModal) {
                scrollTarget = $(first)[0].offsetTop - offset;
            }
            scrollTo(scrollTarget, duration, isModal, callback);
        }

    }, true);

};

export default scrollToInvalid;
