// support for templates/_partials/page-content.html

import Player from '@vimeo/player';
import Flickity from 'flickity';
import throttle from '../modules/throttle';

const handleBlockquoteLists = function (el) {

  // Matches `@include mq(medium)`
  let matches = window.matchMedia('(min-width: 769px)').matches

  let flickity = new Flickity(el, {
    contain: true,
    wrapAround: true,
    pageDots: false,
    prevNextButtons: false,
    draggable: true,
    selectedAttraction: 0.08,
    friction: 0.5,
    cellAlign: matches ? 'center' : 'left',
  })

}

const PageContentRoute = () => {
    const animationElements = Array.from(document.querySelectorAll(".animation-wrapper"));
    const blockquoteListElements = Array.from(document.querySelectorAll('[data-blockquote-slideshow]'))
    const blocks = [];

    const initBlockquoteList = () => {
      blockquoteListElements.forEach(handleBlockquoteLists)      
    }

    initBlockquoteList()

    window.addEventListener('resize', throttle(initBlockquoteList, 30))

    animationElements.forEach(element => {
        const iframe = element.querySelector("iframe");
        const player = new Player(iframe);
        const playReplayBtn = element.querySelector(".play-replay");
        const stoppedClass = "stopped";
        const playingClass = "playing";

        playReplayBtn.title = "Play";

        /**
        * Use a custom cue point just near the end so we can pause
        * and avoid Vimeo’s required end screen and use a still
        * frame instead.
        */
        player.getDuration().then((duration) => {
            player.addCuePoint(duration - 1, {
                nearEnd: "nearEnd"
            });
        });

        player.on("cuepoint", (event) => {
            if (typeof event.data.nearEnd !== "undefined") {
                player.pause();
            }
        });
        
        player.on("pause", (event) => {
            element.classList.remove(playingClass);
            element.classList.add(stoppedClass);
            playReplayBtn.title = "Replay";
        });
        
        /**
         * We should never get to `ended`!
         */
        player.on("ended", () => {
            player.pause();
        });

        player.on("play", () => {
            element.classList.remove(stoppedClass);
            element.classList.add(playingClass);
            playReplayBtn.title = "Pause";
        });

        playReplayBtn.addEventListener("click", () => {
            player.getPaused().then(function(paused) {
                if (paused) {
                    player.setCurrentTime(0);
                    player.play();
                } else {
                    player.pause();
                }
            });
        })

        blocks.push({
            element: element,
            player: player,
            hasPlayed: false
        });
    });

    if (animationElements.length > 0) {
        window.addEventListener("scroll", (event) => {
            blocks.forEach((block) => {
                if (elementAtCenter(block.element)) {
                    if (!block.hasPlayed) {
                        block.hasPlayed = true;
                        block.player.getPaused().then(function(paused) {
                            if (paused) {
                                block.player.setCurrentTime(0);
                                block.player.play();
                            }
                        });
                    }
                }
            });
        });
    }

    /**
     * Returns `true` if the element has scrolled past the vertical center of
     * the scrolled window.
     * 
     * @param {*} element 
     * @returns boolean
     */
    const elementAtCenter = (element) => {
        // require *some* movement
        if (window.scrollY === 0) {
            return false;
        }

        // element’s vertical distance from the top of the scrolled viewport
        const elementTop = element.getBoundingClientRect().top;
        const visibleWindowHeight = window.innerHeight || document.documentElement.clientHeight;

        return elementTop <= (visibleWindowHeight / 2);
    };

};

export default PageContentRoute;
