import $ from 'jquery';

//
//   Tooltips
//
//////////////////////////////////////////////////////////////////////

const _handleTooltipTouchstart = function (event) {
  if (this && $(this).parents('.list__tooltip').length > 0) {
    event.preventDefault();
  }

  let leftPos = '-25px';
  let rightPos = 'auto';

  if (this && $(this).parents('.l-post__next').length > 0) {
    leftPos = 'auto';
    rightPos = '-25px';
  }

  $('.js-tooltip-box').css({'opacity':0});
  $(this).find('.js-tooltip-box').css({'opacity':1,'top':'auto','bottom':'40px','left':leftPos,'right':rightPos,'marginLeft':0});
}

const _handleTooltipTouchend = function (event) {
  if (this && $(this).parents('.list__tooltip').length > 0) {
    event.preventDefault();
  }

  $('.js-tooltip-box').css({'opacity':0});
}

const _handleTooltipFocus = function (event) {
  _handleTooltipTouchstart.bind(this)(event)

  // When focusing, also elevate the z-index of the parent tier (if any);
  let $parentTier = $(this).parents('.l-pricing-row__col');
  if ($parentTier) {
    $parentTier.css({ 'z-index': '1000 !important' });
  }
}

const _handleTooltipBlur = function (event) {
  // _handleTooltipTouchend.bind(this)(event)

  // When bluring, also clear the customized z-index of the parent tier (if any);
  let $parentTier = $(this).parents('.l-pricing-row__col');
  if ($parentTier) {
    $parentTier.css({ 'z-index': null, });
  }
}

const tooltips = function() {
  const $tooltips = $(".js-tooltip");
  const $pricingCols = $('.l-pricing-row__col');
  
  $tooltips.on('touchstart', _handleTooltipTouchstart);
  $tooltips.on('touchend', _handleTooltipTouchend);

  // $tooltips.on('focus', _handleTooltipFocus);
  // $tooltips.on('blur', _handleTooltipBlur);

  // Hover
  $tooltips.mouseover(function(){
    // Position tooltip relative to mouse coordinates
    $(this).mousemove(function(e) {
      const mouseX = e.pageX - $(this).offset().left;
      const mouseY = e.pageY - $(this).offset().top;
      if (e.pageX > ($(window).width() - $(this).find('.js-tooltip-box').outerWidth())) {
        $(this).find('.js-tooltip-box').css({'top':mouseY - $(this).find('.js-tooltip-box').outerHeight(),'left':mouseX + 10, 'marginLeft':'-' + $(this).find('.js-tooltip-box').outerWidth() + 'px'});   
      }
      else {
        $(this).find('.js-tooltip-box').css({'top':mouseY - $(this).find('.js-tooltip-box').outerHeight(),'left':mouseX + 10});   
      }
    });    
  });
}

export default tooltips;
