import $ from 'jquery';
import ClipboardJS from 'clipboard';

const StyleGuideRoute = () => {

  const $pageAlert = $('.js-page-alert');

  const copyCode = new ClipboardJS('.js-clipboard-btn');
  copyCode.on('success', function(e) {
    window.getSelection().removeAllRanges();
    $pageAlert.addClass('is-visible');
    setTimeout( function(){ 
        $pageAlert.removeClass('is-visible');
      }  , 2000 );
  });
  
};

export default StyleGuideRoute;
