import $ from 'jquery';

const BlogRoute = () => {

  //
  //   Blog Categories
  //
  //////////////////////////////////////////////////////////////////////
  const $catBtns = $('.js-cat-btn');
  const $catDescription = $('.js-cat-description');
  const isVisibleClass = 'is-visible';
  const isHiddenClass = 'is-hidden';
  const isSelectedClass = 'is-selected';

  // Toggling
  $catBtns.bind('click', function() {
    const connectsWith = $(this).attr('data-connects-with');
    const currentCatDescription = $(this).attr('data-content-description');
    $catBtns.removeClass(isSelectedClass);
    $(this).addClass(isSelectedClass);
    $catDescription.addClass(isHiddenClass);
    setTimeout( function() {
      $catDescription.text(currentCatDescription);
      $catDescription.removeClass(isHiddenClass);
    }, 300);
    
  });
  
};

export default BlogRoute;
