import $ from 'jquery';
import '../modules/jQueryAddToWindow.js'

import 'waypoints/lib/jquery.waypoints.js';
import 'sticky-kit/dist/sticky-kit.js';
import enquire from 'enquire.js';
import throttle from '../modules/throttle.js';
import ajaxSearch from '../modules/ajaxSearch.js';
import 'lazysizes/lazysizes.js';
import tooltips from '../modules/tooltips.js';
import * as focusTrap from 'focus-trap';

const GlobalRoute = () => {

  //
  //  Global Classes
  //
  //////////////////////////////////////////////////////////////////////
  const isActiveClass = 'is-active';
  const isClosedClass = 'is-closed';
  const isOpenClass = 'is-open';
  const isVisibleClass = 'is-visible';
  const isScrolledClass = 'is-scrolled';
  const isFlickableClass = 'is-flickable';

  //
  //  Sticky Nav
  //
  //////////////////////////////////////////////////////////////////////
  var $siteHeader = $('.js-site-header');
  var scrollerSensitivity = 10;

  $('.js-header-flick').waypoint({
    handler: function(direction) {
      if (direction == 'down') {
        $siteHeader.addClass(isFlickableClass);
        $('body').addClass(isScrolledClass);
      } else {
        $siteHeader.removeClass(isFlickableClass);
        $('body').removeClass(isScrolledClass);
      }
    },
    offset: "0"
  });

  $(window).on('scroll', function(e){
    var scroller = $(this).scrollTop();
    if ((scroller - scrollerSensitivity) > self.previousScroll){
      $siteHeader.removeClass('is-visible');
    } else if ((scroller + scrollerSensitivity) < self.previousScroll) {
      $siteHeader.addClass('is-visible');
    }
    self.previousScroll = scroller;
  });

  //
  //  Sidebar
  //
  //////////////////////////////////////////////////////////////////////
  const $sidebarItems = $('.js-sidebar-item');
  const $sidebarGroups = $('.js-sidebar-group');
  const $stickyBit = $('.js-sticky-bit');
  const $sidebarToggle = $('.js-sidebar-nav-toggle');

  if ($stickyBit.length > 0) {
    var $stickyOffset = $stickyBit.data('extra-offset');
    var $mq = $stickyBit.data('sticky-mq');

    if ($mq == 'xlarge') {
      enquire.register(APP.mediaQueries.xlarge, {
        match: function() {
          $stickyBit.stick_in_parent({
            offset_top: $stickyOffset
          });
        },
        unmatch: function() {
          $stickyBit.trigger('sticky_kit:detach');
        }
      });
    } else {
      enquire.register(APP.mediaQueries.medium, {
        match: function() {
          $stickyBit.stick_in_parent({
            offset_top: $stickyOffset
          });
        },
        unmatch: function() {
          $stickyBit.trigger('sticky_kit:detach');
        }
      });
    }
  }

  const sectionWaypoints = $('.js-section').waypoint({
    handler: function(direction) {
      // Get the elements we need
      const currentSection = $(this.element).attr('data-section');
      const currentSectionGroup = $(this.element).attr('data-section-group');
      const $currentItem = $sidebarItems.filter(function(){
        return ($(this).attr('data-section') == currentSection);
      });
      const $currentGroup = $sidebarGroups.filter(function(){
        return ($(this).attr('data-section-group') == currentSectionGroup);
      });
      // Toggle classes & Recalc Stick_Kit
      $sidebarItems.removeClass(isActiveClass);
      $currentItem.addClass(isActiveClass);
      $sidebarGroups.not($currentGroup).addClass(isClosedClass);
      $stickyBit.trigger('sticky_kit:recalc');
      $currentGroup.removeClass(isClosedClass);
    },
    offset: 100
  });

  $sidebarToggle.on('click', function() {
    $(this).toggleClass(isOpenClass);
    $sidebarGroups.toggleClass(isVisibleClass);
  });

  //
  //   Tooltips
  //
  //////////////////////////////////////////////////////////////////////
  tooltips();

  //
  //   Button Hovers
  //
  //////////////////////////////////////////////////////////////////////
  $('.js-btn').mousemove(function(e){
    const x = e.pageX - $(this).offset().left - $(this).outerWidth() - 50;
    e.target.style.setProperty('--btn-hover-x', `${ x }px`);
  });

  //
  //   Hover Image Cycle
  //
  //////////////////////////////////////////////////////////////////////
  const $cycleContainer = $('.js-img-cycle');
  const $cycleImg = $('.js-img-cycle-img');
  const $cycleZone = $('.js-img-cycle-zone');

  if ($cycleContainer.length) {

    // Opt-out of custom styling, can provide Tailwind class here, ex. for /starters
    let cycleIsVisibleClass = $cycleContainer.attr('data-img-cycle-visible-class') || isVisibleClass;
    let cycleIsInvisibleClass = $cycleContainer.attr('data-img-cycle-invisible-class') || '';

    // Cycle on Hover
    $cycleZone.on("mouseover", function(){
      const theImageNumber = $(this).attr('data-zone-for');
      const $allImages = $(this).siblings($cycleContainer).children($cycleImg);
      const $theImage = $allImages.filter(function() {
        return ($(this).attr('data-img-num') == theImageNumber);
      });
      $allImages
        .addClass(cycleIsInvisibleClass)
        .removeClass(cycleIsVisibleClass);
      $theImage
        .addClass(cycleIsVisibleClass)
        .removeClass(cycleIsInvisibleClass);
    });
  }

  //
  //  Site Search
  //
  //////////////////////////////////////////////////////////////////////
  const $searchToggle = $('.js-search-toggle');
  const $siteSearch = $('.js-site-search');
  const $siteSearchInput = $('.js-site-search-input');

  // Show and Focus Search

  const siteSearchFocusTrap = focusTrap.createFocusTrap($siteSearch[0], {
    onActivate: () => ($siteSearch[0].classList.add('trap', 'is-active')),
    onDeactivate: () => ($siteSearch[0].classList.remove('is-active')),
    initialFocus: () => $siteSearchInput[0],
    tabbableOptions: {
      // https://github.com/focus-trap/focus-trap-react/issues/785
      displayCheck: 'legacy-full',
    },
  });

  $searchToggle.bind('click', function() {
    $siteSearch.toggleClass(isVisibleClass);
    if ($siteSearch.hasClass(isVisibleClass)) {
      siteSearchFocusTrap.activate();
    } else {
      if (siteSearch) {
        siteSearch.clearResults();
        siteSearchFocusTrap.deactivate();
      }
    }
  });

  const siteSearch = ajaxSearch({
    $scope: $('#js-site-search'),
    $form: $('#js-site-search-form'),
    $formContainer: $('#js-site-search-form-container'),
    $input: $('.js-site-search-input'),
    $resultsContainer: $('#js-site-search-results-container'),
    $results: $('#js-site-search-results')
  });

  //
  //  Try Dropdown
  //
  //////////////////////////////////////////////////////////////////////
  const $tryDropdown = $('.js-try-dropdown');
  const $tryBtn = $('.js-try-btn');
  const $tryModal = $('.try-dropdown__modal');

  const tryFocusTrap = focusTrap.createFocusTrap($tryDropdown[0], {
    onActivate: () => ($tryDropdown[0].classList.add('trap', 'is-active')),
    onDeactivate: () => ($tryDropdown[0].classList.remove('is-active')),
    initialFocus: () => $tryBtn[0],
  });

  $tryBtn.bind('click', function() {
    if ($tryDropdown.hasClass(isVisibleClass)) {
      $('html, body').removeClass('scroll-lock');
      if ($tryModal.hasClass('has-site-notice')) {
        $tryModal.removeClass('has-site-notice');
      }
      tryFocusTrap.deactivate();
    }
    else {
      if ($('.site-notice').length && !$('body').hasClass('is-scrolled')) {
        $tryModal.addClass('has-site-notice');
      }
      $('html, body').addClass('scroll-lock');
      tryFocusTrap.activate();
    }
    $tryDropdown.toggleClass(isVisibleClass);
  });

  //
  //  ESC key Close Modals
  //
  //////////////////////////////////////////////////////////////////////
  $(document).keyup(function(e) {
    if (e.keyCode == 27) { // escape key maps to keycode `27`
      $siteSearch.removeClass(isVisibleClass);
      $tryDropdown.removeClass(isVisibleClass);
      document.activeElement.blur();
      $('html, body').removeClass('scroll-lock');
      if ($tryModal.hasClass('has-site-notice')) {
        $tryModal.removeClass('has-site-notice');
      }
    }
  });

  //
  //  Togglers
  //
  //////////////////////////////////////////////////////////////////////
  const $toggles = $('.js-toggle');
  const isToggledClass = 'is-toggled';

  $toggles.on('click', function() {
    var toToggle = $(this).attr('data-toggle-element');
    // First look for siblings
    var $toToggleEl = $(this).siblings(toToggle);
    // If no siblings, look for closest
    if ($toToggleEl.length === 0) {
      var $toToggleEl = $(this).closest(toToggle);
    }
    var toggleClass = $(this).attr('data-toggle-class');
    // Toggle others if true
    if ($(this).attr('data-toggle-close-others') == 'true') {
      $(toToggle).not($toToggleEl).addClass(toggleClass);
    }
    $toToggleEl.toggleClass(toggleClass);
    $(this).toggleClass(isToggledClass);
  });

  //
  //  Mobile Nav
  //
  //////////////////////////////////////////////////////////////////////
  const $mobileNavToggle = $('.js-mobile-nav-toggle');
  const $mobileNav = $('.js-mobile-nav');

  $mobileNavToggle.on('click', function() {
    $mobileNav.toggleClass(isVisibleClass);
  });

  // Center items on load
  const alignNavMiddle = function() {
    const $mobileNavInner = $('.js-mobile-nav-inner');
    const mobileNavInnerHeight = $mobileNavInner.outerHeight();
    const mobileNavHeight = $mobileNav.outerHeight();
    $mobileNavInner.css('margin-top', ((mobileNavHeight-mobileNavInnerHeight)/2)+'px');
  }

  alignNavMiddle();
  window.addEventListener('resize', throttle(alignNavMiddle, 30));

  //
  //  Animate Waypoints
  //
  //////////////////////////////////////////////////////////////////////
  $('.js-toggle-waypoint').each(function() {
    var $element = $(this);

    $element.waypoint(function() {
      var toggleClass = $element.attr('data-toggle-class');
      $element.toggleClass(toggleClass);
    }, {
      offset: $element.data('offset')
    });
  });

  //
  //  Toggle (Enable/Disable) Form Submit
  //
  //////////////////////////////////////////////////////////////////////
  const $submitToggler = $('.js-toggle-submit');

  if ($submitToggler.length !== 0) {
    const $submit = $submitToggler.closest('form').find('[type=submit]');
    const toggleSubmit = function(isChecked) {
      if ($submit.length !== 0) {
        $submit.prop('disabled', !isChecked)
      }
    };

    $submitToggler.on('change', function(evt) {
      toggleSubmit(evt.currentTarget.checked);
    });
    toggleSubmit($submitToggler.is(':checked'));
  }

  //
  //  Freeform custom error and success messages
  //
  //////////////////////////////////////////////////////////////////////

  /**
   * Override the way Freeform form errors are displayed. This is a re-implementation
   * of the customizations in the `_form-preface.twig` template for server-side errors.
   */
  const _handleRenderFreeformFormErrors = function(event) {
    const form = this

    // Prevent the default behavior
    event.preventDefault();

    const errors = event.errors;
    const errorBlock = document.createElement('div');
    errorBlock.classList.add('alert', 'alert--error');

    const errorHeading = document.createElement('h6');
    errorHeading.classList.add('alert__heading');
    errorHeading.appendChild(document.createTextNode('Something went wrong!'));
    errorBlock.appendChild(errorHeading);

    const errorBody = document.createElement('div');
    errorBody.classList.add('alert__body');

    const errorMessage = document.createElement('p');
    errorMessage.classList.add('alert__body');

    errorMessage.appendChild(document.createTextNode(form.dataset.errorMessage || 'Form contains errors!'));
    errorBody.appendChild(errorMessage);

    if (errors.length) {
        const errorsList = document.createElement('ul');
        for (let messageIndex = 0; messageIndex < errors.length; messageIndex++) {
            const message = errors[messageIndex];
            const listItem = document.createElement('li');

            listItem.appendChild(document.createTextNode(message));
            errorsList.appendChild(listItem);
        }

        errorBody.appendChild(errorsList);
    }

    errorBlock.appendChild(errorBody);

    // Put the error block in the form layout
    let formLayout = form.querySelector('.js-form-error-container')
    if (formLayout) {
      formLayout.innerHTML = '';
      formLayout.appendChild(errorBlock);
    } else {
      form.insertBefore(errorBlock, form.childNodes[0]);
    }

    // Quick fix for the missing span that Freeform clears from our current button implementation
    window.setTimeout(() => {
      let btns = form.querySelectorAll('.js-btn')
      Array.from(btns).forEach(btn => {
        let span = document.createElement('span')
        span.innerHTML = btn.innerHTML
        span.classList.add('btn__content')
        btn.innerHTML = ''
        btn.appendChild(span)
      });
    }, 1000)

  }

  let freeformForms = document.querySelectorAll('form[data-freeform][data-ajax]')

  Array.from(freeformForms).forEach(form => {
    form.addEventListener('freeform-render-form-errors', _handleRenderFreeformFormErrors.bind(form));
  })

};

export default GlobalRoute;
