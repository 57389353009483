import $ from 'jquery';

//
//   Ajax Form
//
//////////////////////////////////////////////////////////////////////
const ajaxForm = (options) => {

  //
  //   Private Vars
  //
  //////////////////////////////////////////////////////////////////////

  var self = {
    visibleClass: 'is-visible',
    hiddenClass: 'is-hidden',
    errorClass: 'has-error',
    submittingClass: 'is-submitting',
    errorEl: '.js-form-field',
    scrollContainer: '.js-modal'
  };

  //
  //   Public Vars
  //
  //////////////////////////////////////////////////////////////////////

  self.settings = $.extend({
    form: '',
    errorMsg: '',
    successMsg: ''
  }, options);

  //
  //   Private Methods
  //
  //////////////////////////////////////////////////////////////////////

  var _init = function() {
    if (self.settings.form.length < 1) return;
    _addEventListeners();
  };

  var _addEventListeners = function() {
    $(self.settings.form).on('submit', _submit);
  };

  var _submit = function(evt) {
    evt.preventDefault();
    $(self.settings.form).addClass(self.submittingClass);

    const $this = $(this);
    var $submitButton = $(self.settings.form).find('button[type="submit"]');
    $submitButton.prop('disabled', true);

    $.ajax({
      type: 'POST',
      dataType: 'json',
      data: $this.serialize(),
      success: function (response) {
        if (response.success && response.finished) {
          _handleSuccess(response);
        } else if (response.errors) {
          _handleError(response.errors);
        }
      }
    });
  };

  var _reset = function() {
    // reset form elements
    $(self.settings.form)[0].reset();

    // remove error classes
    $(self.settings.form).find(self.errorEl).removeClass(self.errorClass);

    // hide success message
    $(self.settings.successMsg).removeClass(self.visibleClass);

    // hide error messages
    $(self.settings.errorMsg).removeClass(self.visibleClass);

    // remove submitting class
    $(self.settings.form).removeClass(self.submittingClass);
  };

  var _handleSuccess = function() {
     _reset();

     // hide form
    $(self.settings.form).addClass(self.hiddenClass);

    // show success message
    $(self.settings.successMsg).addClass(self.visibleClass);

    _scrollTo(0);
  };

  var _handleError = function(errors) {
    // show error message
    $(self.settings.errorMsg).addClass(self.visibleClass);

    // clear any existing error classes
    $(self.settings.form).find('input, select, textarea').closest(self.errorEl).removeClass(self.errorClass);

    // add error class to fields
    Object.keys(errors).forEach(function(key, idx) {
      const $field = $(self.settings.form).find('[name^="' + key + '"]').closest(self.errorEl);
      $field.addClass(self.errorClass);
    });

    // scroll to error message
    _scrollToEl(self.settings.errorMsg);

    // enable submit button
    var $submitButton = $(self.settings.form).find('button[type="submit"]');
    $submitButton.prop('disabled', false);
  };

  var _inViewport = function(el) {
    const rect = $(el)[0].getBoundingClientRect();
    const vpTop = $(window).scrollTop();
    const vpBottom = vpTop + $(window).height();

    return rect.bottom > vpTop && rect.top < vpBottom;
  };

  var _scrollToEl = function(el) {

    if (!_inViewport(el)) {
      const targetTop = $(el).offset().top * -1;

      _scrollTo(targetTop);
    }
  };

  var _scrollTo = function(offset) {
    // if we're not inside a modal, scroll html/body
    const $container = $(self.scrollContainer).length > 0 ? $(self.scrollContainer) : $('html, body');

    $container.animate({
      scrollTop: offset
    }, 500);
  };

  // Initialize
  _init();

  // Return the Object
  return self;
}

export default ajaxForm;
