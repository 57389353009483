import $ from 'jquery';

//
//   Scroll To
//
//////////////////////////////////////////////////////////////////////
const scrollTo = (offset = 0, duration = 500, isModal = false, callback) => {
  const scrollTarget = isModal ? $('.js-modal') : $('html, body');
  scrollTarget.animate({
    scrollTop: offset
  }, duration, function() {
    if (typeof callback == 'function') { 
      callback(); 
    }
  });
}
export default scrollTo;
