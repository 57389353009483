import $ from 'jquery';
import scrollTo from '../modules/scrollTo.js';
import scrollToInvalid from '../modules/scrollToInvalid.js';

const ContactRoute = () => {

  const form = document.querySelector('.js-form form');

  //
  //   Contact Form logic
  //
  //////////////////////////////////////////////////////////////////////

  // Private Vars
  const self = {
    // selectors for _toggleHiddenContent method
    $togglerInput: $('.js-contact-toggler select'),
    $togglerContent: $('.js-contact-toggler-content'),
    togglerValAttr: 'data-display-value',

    // selectors for _setDynamicRecipient method
    $recipientValInput: $('.js-contact-recipient-value select'),
    $recipientEmailInput: $('.js-contact-recipient-email select'),
    $recipientEmailInputOptions: $('.js-contact-recipient-email select option'),
    defaultEmailVal: 'default'
  };

  // Private Methods
  const _init = function() {
    if (self.$togglerInput.length != 0) {

      self.$togglerInput.on('change', _toggleHiddenContent);
    }

    if (self.$recipientValInput.length != 0 && self.$recipientEmailInput.length != 0) {
      self.$recipientValInput.on('change', _setDynamicRecipient);
    }

    _setInitialState();
  };

  const _normalizeStr = function(val) {
    return val.toLowerCase();
  };

  const _getSelectVal = function(el) {
    let val = $(el).val().length != 0 ? $(el).val() : self.defaultEmailVal;
    return _normalizeStr(val);
  };

  const _attrStrToArr = function(el) {
    let attrVals = $(el).find(`[${self.togglerValAttr}]`).attr(self.togglerValAttr);
    attrVals = _normalizeStr(attrVals).split(',') || [];

    return attrVals.map(function(str, idx) {
      return str.trim();
    });
  };

  const _hasAttr = function(el) {
    return $(el).find(`[${self.togglerValAttr}]`).length != 0;
  };

  const _setInitialState = function() {
    _toggleHiddenContent.call(self.$togglerInput[0]);
    _setDynamicRecipient.call(self.$recipientValInput[0]);
  };

  const _toggleHiddenContent = function() {
    const currentVal = _getSelectVal(this);
    let isHidden = false;

    self.$togglerContent.each(function(idx, el) {
      if (_hasAttr(el)) {
        const displayVals = _attrStrToArr(el);
        isHidden = displayVals.indexOf(currentVal) === -1;
      } else {
        isHidden = currentVal === self.defaultEmailVal;
      }

      $(el).toggleClass('is-hidden', isHidden);
    });

    if (!isHidden) {
      const top = self.$togglerInput.offset().top - 100; // 100px cushion at the top
      scrollTo(top);
    }
  };

  const _setDynamicRecipient = function() {
    const currentVal = _getSelectVal(this);

    self.$recipientEmailInputOptions.each(function(idx, option) {
      option.selected = _normalizeStr(option.text) === currentVal;
    });
  };

  // Initialize
  if (form) {
    scrollToInvalid(form);
    _init();
  }

  // Return the Object
  return self;
};

export default ContactRoute;
