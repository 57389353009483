import $ from 'jquery';
import scrollTo from '../modules/scrollTo.js';
import scrollToInvalid from '../modules/scrollToInvalid.js';

const SupportServicesRoute = () => {

  //
  //   Scroll to Invalid Field
  //
  //////////////////////////////////////////////////////////////////////

  const form = document.querySelector('.js-form form');
  if (form) {
    scrollToInvalid(form);
  }

  //
  //   Scroll to Form
  //
  //////////////////////////////////////////////////////////////////////
  const $cardOfferingBtn = $('.js-card-offering-btn');
  const $moreInfoForm = $('.js-form');
  
  const _scrollToFormAndSelect = function (evt) {
    evt.preventDefault();

    // Scroll to form
    const scrollTarget = $moreInfoForm.offset().top - 20; // 20px cushion at the top
    scrollTo(scrollTarget);

    // Select the option that relates to the card the user clicked on
    const optionSelected = $(this).data('option');
    // Reset checked checkboxes
    $moreInfoForm.find('input:checkbox').each(function (i, item) {
      this.checked = item.defaultChecked;
    });
    // Set checked checkbox
    $moreInfoForm.find('input[value="' + optionSelected + '"]').prop('checked', true);
  };

  $cardOfferingBtn.on('click', _scrollToFormAndSelect);
};

export default SupportServicesRoute;
