import $ from 'jquery';
import ajaxSearch from '../modules/ajaxSearch.js';

const KbArticleRoute = (options) => {

  //
  //   Search KB Articles
  //
  //////////////////////////////////////////////////////////////////////
  ajaxSearch({
    $scope: $('#js-search'),
    $form: $('#js-search-form'),
    $input: $('.js-search-input'),
    $resultsContainer: $('#js-search-results-container'),
    $results: $('#js-search-results')
  });

};

export default KbArticleRoute;
