import $ from 'jquery';
import jQueryBridget from 'jquery-bridget';
import Flickity from 'flickity';
import maxHeight from '../modules/maxHeight.js';
import enquire from 'enquire.js';

Flickity.setJQuery($);
jQueryBridget('flickity', Flickity, $);

const ProductRoute = () => {

  //
  //   Why Slider
  //
  //////////////////////////////////////////////////////////////////////
  const $whyBtns = $('.js-slider-btn');
  const $whyContents = $('.js-slider-content');
  const $whyContentsContainer = $('.js-slider-contents-container');
  const $productSlideshow = $('.js-product-slideshow');
  const isVisibleClass = 'is-visible';
  const isSelectedClass = 'is-selected';
  const slideshowBreakpoint = 'screen and (max-width: 879px)';

  // Toggling
  $whyBtns.bind('click', function() {
    const connectsWith = $(this).attr('data-connects-with');
    $whyBtns.removeClass(isSelectedClass);
    $whyContents.removeClass(isVisibleClass);
    $(this).addClass(isSelectedClass);
    const connectsWithContent = $whyContents.filter(function() {
      return $(this).attr('data-connects-with') == connectsWith;
    }).addClass(isVisibleClass);
  });

  // Slideshow for Mobile
  enquire.register(slideshowBreakpoint, {
    unmatch: function() {
      if ($productSlideshow.flickity()) {
        $productSlideshow.flickity('destroy');
      }
      // Get the largest height
      const whySliderHeight = maxHeight($whyContents);
      $whyContentsContainer.css('min-height', whySliderHeight);
    },
    match: function() {
      $productSlideshow.flickity({
        cellSelector: '.js-product-slideshow-slide',
        wrapAround: true,
        pageDots: true,
        prevNextButtons: false,
        draggable: true,
        selectedAttraction: 0.08,
        friction: 0.5
      });
    },
    setup: function() {
      // Get the largest height
      const whySliderHeight = maxHeight($whyContents);
      $whyContentsContainer.css('min-height', whySliderHeight);
    }
  });
  
};

export default ProductRoute;
