import billingSwitch from "../modules/billing-switch";

const initPricingTableHeader = (container) => {
  const header = container.querySelector('[data-pricing-table-header]')
  const headerText = header.querySelector('h2')
  const sections = [...container.querySelectorAll('.pricing-table .pricing-table__section')]

  let prevYPosition = 0
  let direction = 'up'

  // Building an array of numbers starting at 0.00 and incrementing at every 0.01
  const options = {
    rootMargin: `${header.offsetHeight * -1}px`,
    threshold: 0,
    // threshold: Array.apply(null, {length: 100}).map((n, i) => i/100)
  }

  const getTargetSection = (entry) => {
    const index = sections.findIndex((section) => section == entry.target)
    
    if (index >= sections.length - 1) {
    return entry.target
    } else {
    return sections[index + 1]
    }
  }

  const shouldUpdate = (entry) => {
    if (direction === 'down' && !entry.isIntersecting) {
      return true
    }

    if (direction === 'up' && entry.isIntersecting) {
      return true
    }
    
    return false
  }

  const updateHeader = (target) => {
    let heading = target.querySelector('h2')
    if (heading) {
      let title = heading.innerHTML
      if (title) {
        headerText.innerHTML = title
      }
    }
  }

  const onIntersectSection = (entries, observer) => {
    entries.forEach((entry) => {
      // console.log(window.scrollY, prevYPosition)
      
      if (window.scrollY > prevYPosition) {
        direction = 'down'
      } else {
        direction = 'up'
      }
      
      prevYPosition = window.scrollY
      
      const target = direction === 'down' ? getTargetSection(entry) : entry.target

      if (shouldUpdate(entry)) {
        updateHeader(target)
      }

      // Fade
      // if (entry.isIntersecting) {
      //  // Rounding the intersectionRatio to 2 decimals
      //  const scale = Math.round((entry.intersectionRatio)*100)/100;
      //  entry.target.style.opacity = scale;
      // }
    })
  }

  document.addEventListener('readystatechange', e => {
    if (e.target.readyState === 'complete') {
      updateHeader(sections[0])
    }
  })

  const obsSection = new IntersectionObserver(onIntersectSection, options)

  sections.forEach((section) => {
    obsSection.observe(section)
  })

  const onIntersectHeader = (entries, observer) => {
    entries.forEach((entry) => {
      entry.target.classList.toggle('pricing-table__header--sticky', entry.intersectionRatio < 1)
    })
  }

  /**
   * Observe the header, to add and remove the shadow. CSS-only approaches no
   * longer work in iOS.
   * @see https://stackoverflow.com/a/57991537/864799
   */
  const initHeader = () => {
    const obsHeader = new IntersectionObserver(onIntersectHeader, {
      threshold: [1],
      rootMargin: '-1px 0px 0px 0px',
    })

    obsHeader.observe(header)
  }

  initHeader()
}

const PricingRoute = () => {

  // Cloud billing switch
  const billingSwitcheEls = document.querySelectorAll('[data-cloud-landing-pricing-tier]')
  Array.from(billingSwitcheEls).forEach(el => {
    let parent = el.parentNode
    if (parent) {
      billingSwitch(parent)
    }
  })

  // Pricing Table
  const pricingTableEls = document.querySelectorAll('[data-pricing-table]')
  Array.from(pricingTableEls).forEach(el => {
    initPricingTableHeader(el)
  })

};

export default PricingRoute;
