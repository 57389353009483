import $ from 'jquery';
import scrollTo from '../modules/scrollTo.js';
import scrollToInvalid from '../modules/scrollToInvalid.js';

const EnterpriseRoute = () => {

  //
  //   Scroll to Invalid Field
  //
  //////////////////////////////////////////////////////////////////////

  const form = document.querySelector('.js-form form');
  if (form) {
    scrollToInvalid(form);
  }

  //
  //   Scroll to Form
  //
  //////////////////////////////////////////////////////////////////////
  const $cardOfferingBtn = $('.js-card-offering-btn');
  const $moreInfoForm = $('.js-form');
  // const $moreInfoField = $('#form-input-moreInformation');

  const _scrollToFormAndSelect = function(evt) {
     evt.preventDefault();

     // Scroll to form
     const scrollTarget = $moreInfoForm.offset().top - 20; // 20px cushion at the top
     scrollTo(scrollTarget);

     // Select the option that relates to the card the user clicked on
    //  const optionSelected = $(this).data('option');
    //  $moreInfoForm.find('option[value="' + optionSelected + '"]').prop('selected', true);
  };

  $cardOfferingBtn.on('click', _scrollToFormAndSelect);
};

export default EnterpriseRoute;
