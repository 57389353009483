import $ from 'jquery';
import Flickity from 'flickity';
import jQueryBridget from 'jquery-bridget';
import 'flickity-imagesloaded'

Flickity.setJQuery($);

// TODO Check if we need this in `home.js` as well, and if
// not, maybe make it a utility we run in `app.js` instead.

/** @see https://github.com/metafizzy/flickity/issues/534#issuecomment-328859144 */
Flickity.prototype._createResizeClass = function() {
  this.element.classList.add('flickity-resize');
};

Flickity.createMethods.push('_createResizeClass');

var resize = Flickity.prototype.resize;
Flickity.prototype.resize = function() {
  this.element.classList.remove('flickity-resize');
  resize.call( this );
  this.element.classList.add('flickity-resize');
};

jQueryBridget('flickity', Flickity, $);

const EventsRoute = () => {

  // Events Videos
  //--------------------------------------------------------------------
  const $eventsSlideshow = $('.js-events-videos');
  const eventsSlideSelector = '.js-events-videos-slide';

  // init Flickity
  $eventsSlideshow.flickity({
    cellSelector: eventsSlideSelector,
    wrapAround: false,
    pageDots: false,
    prevNextButtons: false,
    draggable: true,
    selectedAttraction: 0.08,
    friction: 0.5,
    imagesLoaded: true,
    cellAlign: 'left',
    contain: true
  });
};

export default EventsRoute;
