import $ from 'jquery';
import enquire from 'enquire.js';
import jQueryBridget from 'jquery-bridget';
import Flickity from 'flickity';
import 'flickity-imagesloaded';
import isInViewport from '../modules/isInViewport.js';
import throttle from '../modules/throttle.js';
import * as focusTrap from 'focus-trap';

Flickity.setJQuery($);
jQueryBridget('flickity', Flickity, $);

const HomeRoute = () => {

  //
  //   Slideshows
  //
  //////////////////////////////////////////////////////////////////////

  //   Features
  //--------------------------------------------------------------------
  const $featureSlideshowBtns = $('.js-slider-btn');
  const $featureSlideshow = $('.js-feature-slideshow');
  const featuredSlideSelector = '.js-feature-slideshow-slide';
  const isSelectedClass = 'is-selected';

  // init Flickity
  $featureSlideshow.flickity({
    cellSelector: featuredSlideSelector,
    wrapAround: true,
    pageDots: false,
    prevNextButtons: false,
    draggable: true,
    selectedAttraction: 0.08,
    friction: 0.5,
    imagesLoaded: true,
    on: {
      change: function(index) {
        $featureSlideshowBtns.removeClass(isSelectedClass);
        $featureSlideshowBtns.filter(function() {
          return $(this).attr('data-connects-with') == index;
        }).addClass(isSelectedClass);
      },
    }
  });

  // Connecting to Buttons
  $featureSlideshowBtns.bind('click', function() {
    const connectsWith = $(this).attr('data-connects-with');
    $featureSlideshow.flickity().flickity('select', connectsWith);
  });

  // Select slides when clicked on
  $featureSlideshow.on('staticClick.flickity', function(ev) {
    let $slide = $(ev.originalEvent.srcElement);
    if (!$slide.is(featuredSlideSelector)) {
      $slide = $slide.closest(featuredSlideSelector);
      if (!$slide.length) {
        return;
      }
    }
    $featureSlideshow.flickity('select', $slide.attr('data-index'));
  });


  //   In the Field
  //--------------------------------------------------------------------
  const initGallery = ($mockupSlideshow) => {
    const mockupSlideSelector = '.js-mockup-gallery-slide';

    // init Flickity
    $mockupSlideshow.flickity({
      cellSelector: mockupSlideSelector,
      wrapAround: true,
      pageDots: true,
      prevNextButtons: false,
      draggable: true,
      selectedAttraction: 0.08,
      imagesLoaded: true,
      friction: 0.5
    });
  
    // Select slides when clicked on
    $mockupSlideshow.on('staticClick.flickity', function(ev) {
      let $slide = $(ev.originalEvent.srcElement);
      if (!$slide.is(mockupSlideSelector)) {
        $slide = $slide.closest(mockupSlideSelector);
        if (!$slide.length) {
          return;
        }
      }
      $mockupSlideshow.flickity('select', $slide.attr('data-index'));
    });
  }

  const $mockupSlideshow = $('.js-mockup-gallery');
  initGallery($mockupSlideshow);
  

  //   Feature Overview
  //--------------------------------------------------------------------
  const $featureOverview = $('.js-feature-overview');
  const slideshowBreakpoint = 'screen and (max-width: 879px)';

  $featureOverview.flickity({
    cellSelector: '.js-feature-overview-item',
    wrapAround: true,
    pageDots: true,
    prevNextButtons: false,
    draggable: true,
    watchCSS: true,
    selectedAttraction: 0.08,
    friction: 0.5
  });

  //   Projects Gallery Lite
  //--------------------------------------------------------------------
  const $projectsGalleryLite = $('.js-projects-gallery-lite');
  const $projectsGalleryLiteCarousel = $('.js-projects-gallery-lite-carousel');

  const carousels = [];

  $projectsGalleryLiteCarousel.each((index) => {
    const $row = $($projectsGalleryLiteCarousel[index])
    const rowReverse = index % 2 != 0
    const cellSelector = '.js-projects-gallery-lite-slide'

    $row.flickity({
      cellSelector: cellSelector,
      autoPlay: false,
      rightToLeft: rowReverse,

      wrapAround: true,
      pageDots: false,
      prevNextButtons: false,
      draggable: true,
      selectedAttraction: 0.08,
      friction: 0.5,
      imagesLoaded: true,
      cellAlign: rowReverse ? 'right' : 'left',
      contain: true
    });

    carousels.push($row)

  })

  setTimeout(() => {
    carousels.forEach(($carousel) => {
      if ($carousel && typeof $carousel.flickity === 'function') {
        $carousel.flickity('playPlayer')
      }
    })
  }, 1000)

};

export default HomeRoute;
