const _handleUpdatePricingContent = (els, checked) => {
  els.forEach(el => {
    let newValue = el.getAttribute(checked ? 'data-checked' : 'data-unchecked')
    if (newValue) {
      el.innerHTML = newValue
    }
  })
}

const _createBillingSwitch = (tier) => {
  let checkbox = tier.querySelector('input[name="cloud-billing"]')

  if (checkbox) {
    let price = tier.querySelector('[data-price]')

    let toggleItems = [...tier.querySelectorAll('[data-checked]')]
  
    checkbox.addEventListener('change', (e) => {
      _handleUpdatePricingContent(toggleItems, e.target.checked)
    })
  
    // Update the content on load, based on checkbox
    _handleUpdatePricingContent(toggleItems, checkbox.checked)
  }
}

const billingSwitch = (container) => {
  container = container || document
  let tiers = container.querySelectorAll('[data-cloud-landing-pricing-tier]')

  if (tiers) {
    Array.from(tiers).forEach(_createBillingSwitch)
  }
}

export default billingSwitch
