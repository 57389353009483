import scrollToInvalid from '../modules/scrollToInvalid.js';

const FindAPartnerRoute = () => {

  //
  //   Scroll to Invalid Field
  //
  //////////////////////////////////////////////////////////////////////

  const form = document.querySelector('.js-form form');
  if (form) {
    scrollToInvalid(form);
  }

};

export default FindAPartnerRoute;
