import $ from 'jquery';
import isInViewport from '../modules/isInViewport.js';
import throttle from '../modules/throttle.js';

const AboutRoute = () => {

  //
  //   Parallax Blocks
  //
  //////////////////////////////////////////////////////////////////////
  const $aboutPhotos = $('.js-about-hero-photo');
  const $pluginBlocksContainer = $('.js-about-hero');
  const pluginBlocksContainerOffset = $pluginBlocksContainer.offset().top;

  function parallaxBlocks(element) {

    function plax() {
      if (isInViewport($pluginBlocksContainer[0], 100)) {
        $.each(element, function() {
          var scrollTop = $(window).scrollTop() - pluginBlocksContainerOffset;
          var intensity = $(this).attr('data-intensity');
          var imgPos = scrollTop / intensity - scrollTop + 'px';
          $(this).css('transform', 'translateY(' + imgPos + ')');
        });
      }
    }

    // Trigger on Load
    $.each(element, function() {
      
      if (isInViewport($pluginBlocksContainer[0], 100)) {
        $.each(element, function() {
          var scrollTop = $(window).scrollTop() - pluginBlocksContainerOffset;
          var intensity = $(this).attr('data-intensity');
          var imgPos = scrollTop / intensity - scrollTop + 'px';
          $(this).css('transform', 'translateY(' + imgPos + ')');
        });
      }
    });
    
    // Throttle on Scroll
    window.addEventListener('scroll', throttle(plax, 30));
  }

  parallaxBlocks($aboutPhotos); 

  // $pluginBlocksContainer.find('img').on('lazybeforeunveil', function() {
  //   lazySizes.loader.unveil($(this));
  //   $(this).parents('.plugin-block').removeClass('is-hidden');
  // });

  const heroImages = $pluginBlocksContainer.find('img');
  $.each(heroImages, function() {
    $(this).on('lazybeforeunveil', function() {
      $(this).parents('.plugin-block').removeClass('is-hidden');
    });
  });
  
};

export default AboutRoute;
