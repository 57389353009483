import $ from 'jquery';
import scrollToInvalid from '../modules/scrollToInvalid.js';
import ClipboardJS from 'clipboard';
import ajaxFilter from '../modules/ajaxFilter.js';
import ajaxForm from '../modules/ajaxForm.js';

const PartnersRoute = () => {
  //
  //   Scroll to Invalid Field
  //
  //////////////////////////////////////////////////////////////////////

  const form = document.querySelector('.js-form form');
  if (form) {
    scrollToInvalid(form, 0, 0, true);
  }

  //
  //   Copy to Clipboard
  //
  //////////////////////////////////////////////////////////////////////
  const $pageAlert = $('.js-page-alert');

  const copyCode = new ClipboardJS('.js-clipboard-btn');
  copyCode.on('success', function(e) {
    window.getSelection().removeAllRanges();
    $pageAlert.addClass('is-visible');
    setTimeout( function(){ 
        $pageAlert.removeClass('is-visible');
      }  , 2000 );
  });


  //
  //   Modal
  //
  //////////////////////////////////////////////////////////////////////
  const $modalToggle = $('.js-modal-toggle');
  const $modal = $('.js-modal');
  const isVisibleClass = 'is-visible';

  $modalToggle.on('click', function() {
    $modal.toggleClass(isVisibleClass);
  });


  //
  //   Partner Filtering
  //
  //////////////////////////////////////////////////////////////////////
  ajaxFilter();

  //
  //   Ajax Form
  //
  //////////////////////////////////////////////////////////////////////
  ajaxForm({
    form: '#js-hire-partner-form',
    errorMsg: '.js-hire-form-errors',
    successMsg: '.js-hire-form-success'
  });

};

export default PartnersRoute;
