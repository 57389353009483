import scrollTo from '../modules/scrollTo';
import scrollToInvalid from '../modules/scrollToInvalid';

const DemosRoute = () => {
  const form = document.querySelector(".js-form form");

  //
  //   Demos Form logic
  //
  //////////////////////////////////////////////////////////////////////

  // Private Vars
  const self = {
    $formContainer: $(".js-form"),
    $projectDemosBtns: $(".l-project-demos .js-btn"),
  };

  // Private Methods
  const _init = function (freeformForm) {
    if (self.$projectDemosBtns.length != 0) {
      self.$projectDemosBtns.on("click", _requestDemo.bind(freeformForm));
    }
  };

  /**
   * Re-render conditional fields, now that we’ve manually changed a value.
   * @see https://github.com/solspace/craft-freeform/discussions/1113#discussioncomment-8698689
   */
  const _resetFreeformHandlers = function(field) {
    ['click', 'change', 'keyup'].forEach(listener => {
      let event = new Event(listener)
      field.dispatchEvent(event)
    })
  }

  const _requestDemo = function (e) {
    const freeform = this
    const freeformEl = this.form
    const demoKind = e.target.dataset['demoKind'];

    if (freeformEl && demoKind) {
      let field = freeformEl.elements['demoKind']

      // Handle radio groups
      let isInputList = typeof field === 'object' && typeof field.length !== 'undefined'
      let arr = isInputList ? field : [field]
      arr.forEach(f => _resetFreeformHandlers(f))

      field.value = demoKind
    }

    if (self.$formContainer.hasClass("is-visually-hidden")) {
      self.$formContainer.removeClass("is-visually-hidden");
    }

    const top = self.$formContainer.offset().top; // - 100; // 100px cushion at the top
    scrollTo(top);
  };

  // Initialize
  if (form) {
    scrollToInvalid(form);
    
    // Wait until Freeform is ready, otherwise conditional fields won’t work yet
    form.addEventListener('freeform-ready', (e) => {
      _init(e.freeform);
    });  
  }

  // Return the Object
  return self;
};

export default DemosRoute;
