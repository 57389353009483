import $ from 'jquery';
import jQueryBridget from 'jquery-bridget';
import ajaxFilter from '../modules/ajaxFilter.js';
import Flickity from 'flickity';
import 'flickity-imagesloaded';

Flickity.setJQuery($);
jQueryBridget('flickity', Flickity, $);

const InTheFieldroute = () => {

  //
  //   Mockup Slideshow
  //
  //////////////////////////////////////////////////////////////////////
  const $mockupSlideshow = $('.js-mockup-gallery');
  const isSelectedClass = 'is-selected';
  const isSettledClass = 'is-settled';

  // init Flickity
  $mockupSlideshow.flickity({
    cellSelector: '.js-mockup-gallery-slide',
    wrapAround: true,
    pageDots: true,
    prevNextButtons: false,
    draggable: true,
    selectedAttraction: 0.08,
    friction: 0.5,
    imagesLoaded: true
  });


  //
  //   In the Field Filtering
  //
  //////////////////////////////////////////////////////////////////////
  ajaxFilter();

};

export default InTheFieldroute;
