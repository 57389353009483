import $ from 'jquery';
import tooltips from '../modules/tooltips.js'

//
//   Ajax Filter
//
//////////////////////////////////////////////////////////////////////
const ajaxFilter = (options) => {

  // Private Vars
  const self = {
    hiddenClass: 'is-hidden'
  };

  // Pubic Vars
  self.settings = $.extend({
    $form: $('#js-ajax-filter'),
    $results: $('#js-ajax-filter-results')
  }, options);

  // Private Methods
  const _init = function() {
    if (self.settings.$form.length === 0) return;

    self.$inputs = self.settings.$form.find('input, select, textarea').not('[type=hidden], [type=button], [type=submit], [type=reset]');
    self.$clear = self.settings.$form.find('input[type=reset]');
    self.hasClear = self.$clear.length !== 0;

    _addEventListeners();
  };

  const _addEventListeners = function() {
    self.settings.$form.on('submit', _handleSubmit);
    self.$inputs.on('change', _handleChange);

    if (self.hasClear) {
      self.$clear.on('click', _handleClear);
    }
  };

  const _handleClear = function(evt) {
    self.$inputs.each(function(idx, el) {
      el.value = '';
    });

    // We only want to trigger the change event once
    self.$inputs.eq(0).change();
  };

  const _handleChange = function(evt) {
    self.settings.$form.submit();
  };

  const _handleSubmit = function(evt) {
    evt.preventDefault();

    const $this = $(this);
    const data = $this.serialize();
    const url = `${$this.attr('action')}?${data}`;

    _makeRequest(url);
  };

  const _makeRequest = function(url) {
    history.replaceState({}, document.title, url);
    
    $.ajax({
      method: 'GET',
      url,
      beforeSend () {
        self.settings.$results.addClass('is-loading');
      },
      success (data, textStatus) {
        self.settings.$results.html(data);
      },
      complete () {
        if (self.hasClear) {
          self.$clear.toggleClass(self.hiddenClass, _selectedCount() === 0);
        }
        self.settings.$results.removeClass('is-loading');
        tooltips(); // Run for any tooltips that are on the ajax'd content
      },
      error (jqXHR, textStatus, error) {
        self.settings.$results.html('Oops, something went wrong. Please try again.');
      }
    });
  }

  const _selectedCount = function() {
    let count = 0;

    self.$inputs.each(function(idx, el) {
      if (el.value !== '') count++
    });

    return count;
  }

  // Initialize
  _init();

  // Return the Object
  return self;
}

export default ajaxFilter;
